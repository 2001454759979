.section-title {
    align-items: center;
}

.title-text-house {
    text-align: center;
    font-size: 35px;
    margin: 1em;
    font-family: 'Lemonada', cursive;
}

.container1 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 300px;
    grid-gap: 10px;
    margin-left: 15em;
    margin-right: 15em;
    margin-bottom: 10px;
}

.container2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 400px;
    grid-gap: 10px;
    margin-left: 15em;
    margin-right: 15em;
    margin-bottom: 10px;
}

.container3 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 400px;
    grid-gap: 10px;
    margin-left: 15em;
    margin-right: 15em;
}

.gallery-item {
    width: 100%;
    height: 100%;
    position: relative;
}

.gallery-item .image {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.gallery-item .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (max-width: 1300px) {
    .container1 {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: 300px;
        grid-gap: 10px;
        margin-left: 50px;
        margin-right: 50px;
        margin-bottom: 10px;
    }

    .container2 {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: 300px;
        grid-gap: 10px;
        margin-left: 50px;
        margin-right: 50px;
        margin-bottom: 50px;
    }

    .container3 {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: 300px;
        grid-gap: 10px;
        margin-left: 50px;
        margin-right: 50px;
        margin-bottom: 50px;
    }
}