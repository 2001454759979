@import url('https://fonts.googleapis.com/css2?family=Tajawal&display=swap');

.container {
    -webkit-box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    font-family: 'RobotoDraft', 'Roboto', sans-serif;
    display: inline-block;
    margin: 1em;
}

.property-card
{
  height:30em;
  width:25em;
  margin-left: auto;
  margin-right: auto;
  margin-left: 1.5em;
  margin-right: 1.5em;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
  -ms-flex-direction:column;
  flex-direction:column;
  position:relative;
  -webkit-transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius:5px;
  overflow:hidden;
  -webkit-box-shadow:  15px 15px 27px #e1e1e3, -15px -15px 27px #ffffff;
  box-shadow:  15px 15px 27px #e1e1e3, -15px -15px 27px #ffffff;
}

.property-image
{
  height:13em;
  width: 25em;
  padding:1em 2em;
  position:Absolute;
  top: 0px;
  -webkit-transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  background-size:cover;
  background-repeat:no-repeat;
}

.property-description
{
  background-color: #FAFAFC;
  height:17em;
  width:25em;
  position:absolute;
  bottom:0em;
  -webkit-transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 0.5em 1em;
  text-align:center;
}

.property-description p {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 15px;
    font-family: 'Roboto Slab', serif;
    margin-bottom: 5px;
}

@media screen and (max-width: 1300px) {
    .property-card {
        margin-left: 1.5em;
        margin-right: 1.5em;
    }
}

@media screen and (max-width: 800px) {
  .property-card {
    width: 20em;
  }
  .property-image {
    width: 20em;
  }

  .property-description {
    width: 20em;
    padding-bottom: 15px;
  }

  .property-description p {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 13px;
  }
}

@media screen and (max-width: 700px) {
  .property-card {
    width: 17em;
  }
  .property-image {
    width: 17em;
  }

  .property-description {
    width: 17em;
  }
}


.property-card:hover .property-description
{
  height:0em;
  padding:0px 1em;
}

.property-card:hover .property-image
{
  height:25em;
}

.property-card:hover
{
    background-color:white;
    cursor:pointer;
}

.property-description h5 {
    margin:0px;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size:22px;
    font-weight:700;
    font-family: 'Tajawal', sans-serif;
}

/* -- Modal -- */

.modal-rooms {
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
  }
  
  /* Modal Content (image) */
  .modal-rooms-content {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
  }
  
  /* Caption of Modal Image */
  .caption {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    text-align: center;
    color: #ccc;
    padding: 10px 0;
    height: 150px;
  }
  
  /* Add Animation */
  .modal-rooms-content,
  .modal-rooms,
  .caption {
    animation: fadein 1.5s;
    -moz-animation: fadein 1.5s; /* Firefox */
    -webkit-animation: fadein 1.5s; /* Safari et Chrome */
    -o-animation: fadein 1.5s; /* Opera */
  }
  
  @-webkit-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
  }
  
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
        opacity: 1;
    }
  }
  
  /* The Close Button */
  .close-rooms {
    position: absolute;
    top: 100px;
    right: 100px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
  }
  
  .close-rooms:hover,
  .close-rooms:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }
  
  /* 100% Image Width on Smaller Screens */
  @media only screen and (max-width: 700px) {
    .modal-content {
      width: 100%;
    }
  }

.modal-rooms-container-img {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 300px;
    grid-gap: 10px;
    margin-left: 15em;
    margin-right: 15em;
}

.modal-rooms-item-img {
    width: 100%;
    height: 100%;
    position: relative;
}

.modal-rooms-container-img img {
    width: 600px;
    height: 600px;
}

@media screen and (max-width: 1600px) {
    .modal-rooms-container-img img {
        width: 500px;
        height: 500px;
    }
}

@media screen and (max-width: 1400px) {
    .modal-rooms-container-img img {
        width: 400px;
        height: 400px;
    }
}

@media screen and (max-width: 1400px) {
    .modal-rooms-container-img {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: 400px;
    }

    .modal-rooms-content {
        margin-bottom: 3em;
    }
}

@media screen and (max-width: 800px) {
    .modal-rooms-container-img {
        margin-left: 5em;
        margin-right: 5em;
    }
    .modal-rooms-container-img img {
        grid-auto-rows: 200px;
        width: 360px;
        height: 360px;
    }

    .close-rooms {
        top: 100px;
        right: 40px;
    }
}

@media screen and (max-width: 600px) {
  .modal-rooms-container-img {
      margin-left: 0.5em;
      margin-right: 0.5em;
  }
  .modal-rooms-container-img img {
      grid-auto-rows: 200px;
      width: 240px;
      height: 300px;
  }

  .close-rooms {
      top: 100px;
      right: 18px;
  }

  .modal-rooms-item-img {
    width: 80%;
    height: 80%;
    padding-left: 1em;
  }
}