.title-text-landes {
    text-align: center;
    font-size: 35px;
    margin: 1em;
    font-family: 'Lemonada', cursive;
}

.landes-text-container {
    padding-top: 1em;
    text-align: center;
    margin-left: 20em;
    margin-right: 20em;
    margin-bottom: 3em;
}

.landes-text-container p {
    font-family: 'Tajawal', sans-serif;
    font-size: 24px;
}

.container1-hossegor {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 300px;
    grid-gap: 20px;
    margin-left: 15em;
    margin-right: 15em;
    margin-bottom: 10px;
    margin-top: 30px;
}

.container2-hossegor {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 300px;
    grid-gap: 20px;
    margin-left: 15em;
    margin-right: 15em;
    margin-bottom: 30px;
}

.gallery-item-hossegor {
    position: relative;
}

.gallery-item-hossegor .image-hossegor {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.gallery-item-hossegor .image-hossegor img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 1300px) {
    .landes-text-container {
        margin-left: 10em;
        margin-right: 10em;
    }

    .container1-hossegor {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: 300px;
        grid-gap: 10px;
        margin-left: 50px;
        margin-right: 50px;
        margin-bottom: 10px;
    }

    .container2-hossegor {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: 300px;
        grid-gap: 10px;
        margin-left: 50px;
        margin-right: 50px;
        margin-bottom: 50px;
    }
}

@media (max-width: 800px) {
    .landes-text-container {
        margin-left: 2em;
        margin-right: 2em;
    }

    .landes-text-container p {
        font-family: 'Tajawal', sans-serif;
        font-size: 20px;
    }
}