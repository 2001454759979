@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@500&display=swap');

.modal-container {
    position: absolute;
    left: 50%;
    top: 60%;
    width: 50%;
    height: 23em;
    background-color: rgba(35, 34, 32, 0.7);
    transform: translate(-50%, -60%);
    border-radius: 5px;
    text-align: center;
    padding: 1em 1.5em 3em 1.5em;
}

.text-modal-title {
    color: #fefefe;
    text-align: center;
    margin: 0.2em;
    padding-top: 30px;
    font-size: 45px;
    text-decoration: None;
    font-family: 'Tajawal', sans-serif;
}

.text-modal-subtitle {
    color: #fefefe;
    text-align: center;
    text-decoration: None;
    font-size: 25px;
    margin-bottom: 1em;
    font-family: 'Tajawal', sans-serif;
}

.text-modal-text {
    color: #fefefe;
}

.wrapper {
    display: flex;
    align-items: center; /*horizontal centering*/
    justify-content: center; /*vertical centering*/
    flex-direction: column; /*keep the h3 above the textbox*/
}

.line-modal {
    width: 40%;
    height: 20px;
    border-bottom: 1px solid white;
    position: center;
    margin: 0em 0em 1.5em 0em;
    vertical-align: middle;
}

.button-holder {
    background-color: #fefefe;
    width: 10em;
    height: 5em;
    position: center;
    text-align: center;
    vertical-align: middle;
    justify-content: center;
    border-radius: 20px;
}

.button-holder:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

.btn {
    background: #ffffff;
    height: 100%;
    min-width: 150px;
    border: none;
    border-radius: 20px;
    color: #eee;
    font-size: 40px;
    font-family: 'Cookie', cursive;
    position: relative;
    transition: 1s;
    -webkit-tap-highlight-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding-top: 5px;
    padding-bottom: 5px;
}

.btn .circle {
    width: 5px;
    height: 5px;
    background: transparent;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 50%;
    overflow: hidden;
    transition: 500ms;
}

.text-content-modal {
    text-decoration: none;
    font-size: 20px;
    color: black;
}

.btn:hover {
    background: transparent;
}

.btn:hover #circle {
    height: 2em;
    width: 150px;
    left: 0;
    border-radius: 2px;
    border-bottom: 2px solid #000000;
}

.link-modal-home {
    text-decoration: none;
    border: none;
    background: white;
}

@media screen and (max-width: 900px) {
    .modal-container {
        width: 70%;
    }

    .modal-container {
        height: 26em;
    }
}