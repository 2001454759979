.footer {
    height: 4em;
    display: flex;
    align-items: center; /*horizontal centering*/
    justify-content: center; /*vertical centering*/
    flex-direction: column; /*keep the h3 above the textbox*/
}

.line {
    width: 15%;
    height: 20px;
    border-bottom: 1px solid black;
    position: center;
    margin: 0em 0em 1.5em 0em;
    vertical-align: middle;
}

.copyright {
    position: center;
    vertical-align: middle;
    margin-bottom: 3em;
}