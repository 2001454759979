.title-text-services {
    text-align: center;
    font-size: 35px;
    margin-bottom: 1em;
    font-family: 'Lemonada', cursive;
}

.services-row {
    display: flex;
    align-items: left;
}

.services-item p {
    margin-left: 30px;
    margin-bottom: 20px;
    font-size: 17px;
    font-family: 'Lemonada', cursive;
    text-align: left;
}

.service-row {
    height: 2em;
}

.center-icon-pool {
    padding-top: 10px;
    display: flex;
    align-items: center;
}

.center-icon-bike {
    padding-top: 7px;
    display: flex;
    align-items: center;
}

.services-item-breakfast {
    padding-bottom: 22px;
    display: flex;
    align-items: center;
}

.services-item-forest {
    padding-bottom: 16px;
    display: flex;
    align-items: center;
}

.services-item-bed {
    padding-bottom: 32px;
    display: flex;
    align-items: center;
}

.services-list {
    margin-left: 9em;
    margin-right: 6em;
}

.service-container-parent {
    text-align: center;
    margin-top: 2em;
    height: 32em;
}

.service-container {
    background-color: rgba(231, 235, 234, 0.52);
    border-radius: 5px;
    width: 100%;
    height: 100%;
    display: inline-block;
    box-shadow: 7px 4px 4px rgba(0, 0, 0, 0.192);
}

.title-text-services {
    margin-top: 1em;
    margin-bottom: 1em;
}

@media screen and (max-width: 1400px) {
    .service-container-parent {
        height: 40em;
    }
}

@media screen and (max-width: 1200px) {
    .services-list {
        margin-left: 6em;
        margin-right: 3em;
    }
}

@media screen and (max-width: 800px) {
    .services-item p {
        font-size: 12px;
    }

    .services-list {
        margin-left: 1em;
        margin-right: 0.1em;
    }
}