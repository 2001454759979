@import url(https://fonts.googleapis.com/css2?family=Lemonada:wght@300&family=Tajawal:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Tajawal:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Tajawal&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  /*background-image: url('/images/img-2.jpg');*/
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
 /* background-image: url('/images/img-1.jpg');*/
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  /*background-image: url('/images/img-8.jpg');*/
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
.container-img {
    margin-top: 5px;
    height: 80vh;
    background: url(/static/media/exterieur.490ad4d4.jpeg) no-repeat center/cover fixed;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 800px) {
    .container-img {
        background: url(/static/media/exterieur_small.5cd66217.jpeg) no-repeat center/cover;
        background-size: cover;
    }
}

.title-welcome-content {
    font-family: 'Lemonada', cursive;
}

.container-welcome {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 300px;
    grid-gap: 5px;
    margin-left: 5em;
    margin-right: 5em;
    margin-bottom: 10px;
}

.container-item-welcome {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}


.title-welcome {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-content-welcome {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-content-welcome p {
    text-align: center;
    padding: 2em;
    font-size: 25px;
    font-family: 'Tajawal', sans-serif;
}

.title-welcome h2 {
    text-align: center;
    font-size: 33px;
}

.wrapper-line-welcome {
    display: flex;
    align-items: center;
    justify-content: center;
}

.line-welcome {
    width: 30%;
    height: 10px;
    border-bottom: 1px solid black;
    position: center;
    margin: 0em 0em 1.5em 0em;
    vertical-align: middle;
}

@media screen and (max-width: 900px) {
    .container-welcome {
        margin-top: 2em;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        margin-bottom: 50px;
        grid-auto-rows: 200px;
        margin-left: 2em;
        margin-right: 2em;
    }
    .title-welcome br {
        display: none;
    }

    .text-content-welcome p {
        font-size: 20px;
    }
}

.section-title {
    align-items: center;
}

.title-text-house {
    text-align: center;
    font-size: 35px;
    margin: 1em;
    font-family: 'Lemonada', cursive;
}

.container1 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 300px;
    grid-gap: 10px;
    margin-left: 15em;
    margin-right: 15em;
    margin-bottom: 10px;
}

.container2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 400px;
    grid-gap: 10px;
    margin-left: 15em;
    margin-right: 15em;
    margin-bottom: 10px;
}

.container3 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 400px;
    grid-gap: 10px;
    margin-left: 15em;
    margin-right: 15em;
}

.gallery-item {
    width: 100%;
    height: 100%;
    position: relative;
}

.gallery-item .image {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.gallery-item .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (max-width: 1300px) {
    .container1 {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: 300px;
        grid-gap: 10px;
        margin-left: 50px;
        margin-right: 50px;
        margin-bottom: 10px;
    }

    .container2 {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: 300px;
        grid-gap: 10px;
        margin-left: 50px;
        margin-right: 50px;
        margin-bottom: 50px;
    }

    .container3 {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: 300px;
        grid-gap: 10px;
        margin-left: 50px;
        margin-right: 50px;
        margin-bottom: 50px;
    }
}
.modal-container {
    position: absolute;
    left: 50%;
    top: 60%;
    width: 50%;
    height: 23em;
    background-color: rgba(35, 34, 32, 0.7);
    transform: translate(-50%, -60%);
    border-radius: 5px;
    text-align: center;
    padding: 1em 1.5em 3em 1.5em;
}

.text-modal-title {
    color: #fefefe;
    text-align: center;
    margin: 0.2em;
    padding-top: 30px;
    font-size: 45px;
    -webkit-text-decoration: None;
            text-decoration: None;
    font-family: 'Tajawal', sans-serif;
}

.text-modal-subtitle {
    color: #fefefe;
    text-align: center;
    -webkit-text-decoration: None;
            text-decoration: None;
    font-size: 25px;
    margin-bottom: 1em;
    font-family: 'Tajawal', sans-serif;
}

.text-modal-text {
    color: #fefefe;
}

.wrapper {
    display: flex;
    align-items: center; /*horizontal centering*/
    justify-content: center; /*vertical centering*/
    flex-direction: column; /*keep the h3 above the textbox*/
}

.line-modal {
    width: 40%;
    height: 20px;
    border-bottom: 1px solid white;
    position: center;
    margin: 0em 0em 1.5em 0em;
    vertical-align: middle;
}

.button-holder {
    background-color: #fefefe;
    width: 10em;
    height: 5em;
    position: center;
    text-align: center;
    vertical-align: middle;
    justify-content: center;
    border-radius: 20px;
}

.button-holder:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

.btn {
    background: #ffffff;
    height: 100%;
    min-width: 150px;
    border: none;
    border-radius: 20px;
    color: #eee;
    font-size: 40px;
    font-family: 'Cookie', cursive;
    position: relative;
    transition: 1s;
    -webkit-tap-highlight-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding-top: 5px;
    padding-bottom: 5px;
}

.btn .circle {
    width: 5px;
    height: 5px;
    background: transparent;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 50%;
    overflow: hidden;
    transition: 500ms;
}

.text-content-modal {
    text-decoration: none;
    font-size: 20px;
    color: black;
}

.btn:hover {
    background: transparent;
}

.btn:hover #circle {
    height: 2em;
    width: 150px;
    left: 0;
    border-radius: 2px;
    border-bottom: 2px solid #000000;
}

.link-modal-home {
    text-decoration: none;
    border: none;
    background: white;
}

@media screen and (max-width: 900px) {
    .modal-container {
        width: 70%;
    }

    .modal-container {
        height: 26em;
    }
}
.title-text-rooms {
    text-align: center;
    font-size: 35px;
    margin: 1em;
    font-family: 'Lemonada', cursive;
}

.rooms-top-container {
    padding-left: auto;
    padding-right: auto;
    text-align: center;
}
.container {
    -webkit-box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    font-family: 'RobotoDraft', 'Roboto', sans-serif;
    display: inline-block;
    margin: 1em;
}

.property-card
{
  height:30em;
  width:25em;
  margin-left: auto;
  margin-right: auto;
  margin-left: 1.5em;
  margin-right: 1.5em;
  display:flex;
  flex-direction:column;
  position:relative;
  transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius:5px;
  overflow:hidden;
  box-shadow:  15px 15px 27px #e1e1e3, -15px -15px 27px #ffffff;
}

.property-image
{
  height:13em;
  width: 25em;
  padding:1em 2em;
  position:Absolute;
  top: 0px;
  transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  background-size:cover;
  background-repeat:no-repeat;
}

.property-description
{
  background-color: #FAFAFC;
  height:17em;
  width:25em;
  position:absolute;
  bottom:0em;
  transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 0.5em 1em;
  text-align:center;
}

.property-description p {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 15px;
    font-family: 'Roboto Slab', serif;
    margin-bottom: 5px;
}

@media screen and (max-width: 1300px) {
    .property-card {
        margin-left: 1.5em;
        margin-right: 1.5em;
    }
}

@media screen and (max-width: 800px) {
  .property-card {
    width: 20em;
  }
  .property-image {
    width: 20em;
  }

  .property-description {
    width: 20em;
    padding-bottom: 15px;
  }

  .property-description p {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 13px;
  }
}

@media screen and (max-width: 700px) {
  .property-card {
    width: 17em;
  }
  .property-image {
    width: 17em;
  }

  .property-description {
    width: 17em;
  }
}


.property-card:hover .property-description
{
  height:0em;
  padding:0px 1em;
}

.property-card:hover .property-image
{
  height:25em;
}

.property-card:hover
{
    background-color:white;
    cursor:pointer;
}

.property-description h5 {
    margin:0px;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size:22px;
    font-weight:700;
    font-family: 'Tajawal', sans-serif;
}

/* -- Modal -- */

.modal-rooms {
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
  }
  
  /* Modal Content (image) */
  .modal-rooms-content {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
  }
  
  /* Caption of Modal Image */
  .caption {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    text-align: center;
    color: #ccc;
    padding: 10px 0;
    height: 150px;
  }
  
  /* Add Animation */
  .modal-rooms-content,
  .modal-rooms,
  .caption {
    animation: fadein 1.5s;
    -moz-animation: fadein 1.5s; /* Firefox */
    -webkit-animation: fadein 1.5s; /* Safari et Chrome */
    -o-animation: fadein 1.5s; /* Opera */
  }
  
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
        opacity: 1;
    }
  }
  
  /* The Close Button */
  .close-rooms {
    position: absolute;
    top: 100px;
    right: 100px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
  }
  
  .close-rooms:hover,
  .close-rooms:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }
  
  /* 100% Image Width on Smaller Screens */
  @media only screen and (max-width: 700px) {
    .modal-content {
      width: 100%;
    }
  }

.modal-rooms-container-img {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 300px;
    grid-gap: 10px;
    margin-left: 15em;
    margin-right: 15em;
}

.modal-rooms-item-img {
    width: 100%;
    height: 100%;
    position: relative;
}

.modal-rooms-container-img img {
    width: 600px;
    height: 600px;
}

@media screen and (max-width: 1600px) {
    .modal-rooms-container-img img {
        width: 500px;
        height: 500px;
    }
}

@media screen and (max-width: 1400px) {
    .modal-rooms-container-img img {
        width: 400px;
        height: 400px;
    }
}

@media screen and (max-width: 1400px) {
    .modal-rooms-container-img {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: 400px;
    }

    .modal-rooms-content {
        margin-bottom: 3em;
    }
}

@media screen and (max-width: 800px) {
    .modal-rooms-container-img {
        margin-left: 5em;
        margin-right: 5em;
    }
    .modal-rooms-container-img img {
        grid-auto-rows: 200px;
        width: 360px;
        height: 360px;
    }

    .close-rooms {
        top: 100px;
        right: 40px;
    }
}

@media screen and (max-width: 600px) {
  .modal-rooms-container-img {
      margin-left: 0.5em;
      margin-right: 0.5em;
  }
  .modal-rooms-container-img img {
      grid-auto-rows: 200px;
      width: 240px;
      height: 300px;
  }

  .close-rooms {
      top: 100px;
      right: 18px;
  }

  .modal-rooms-item-img {
    width: 80%;
    height: 80%;
    padding-left: 1em;
  }
}
.navbar {
  background: #fafafa;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
}

.navbar-logo {
  color: #131212;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-right: 100px;
  font-family: 'Roboto Slab', serif;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: #131212;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  margin-right: 1em;
  margin-left: 1em;
}

.nav-links:hover {
  border-bottom: 2px solid rgb(0, 0, 0);
  transition: all /*0.2s ease-out*/;
}

.fa-bars {
  color: #131212;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
  color: #131212;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    justify-content: start;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    color:#fbf7f7;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}

.title-text-landes {
    text-align: center;
    font-size: 35px;
    margin: 1em;
    font-family: 'Lemonada', cursive;
}

.landes-text-container {
    padding-top: 1em;
    text-align: center;
    margin-left: 20em;
    margin-right: 20em;
    margin-bottom: 3em;
}

.landes-text-container p {
    font-family: 'Tajawal', sans-serif;
    font-size: 24px;
}

.container1-hossegor {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 300px;
    grid-gap: 20px;
    margin-left: 15em;
    margin-right: 15em;
    margin-bottom: 10px;
    margin-top: 30px;
}

.container2-hossegor {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 300px;
    grid-gap: 20px;
    margin-left: 15em;
    margin-right: 15em;
    margin-bottom: 30px;
}

.gallery-item-hossegor {
    position: relative;
}

.gallery-item-hossegor .image-hossegor {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.gallery-item-hossegor .image-hossegor img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 1300px) {
    .landes-text-container {
        margin-left: 10em;
        margin-right: 10em;
    }

    .container1-hossegor {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: 300px;
        grid-gap: 10px;
        margin-left: 50px;
        margin-right: 50px;
        margin-bottom: 10px;
    }

    .container2-hossegor {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: 300px;
        grid-gap: 10px;
        margin-left: 50px;
        margin-right: 50px;
        margin-bottom: 50px;
    }
}

@media (max-width: 800px) {
    .landes-text-container {
        margin-left: 2em;
        margin-right: 2em;
    }

    .landes-text-container p {
        font-family: 'Tajawal', sans-serif;
        font-size: 20px;
    }
}
.title-contact {
    font-family: 'Lemonada', cursive;
    text-align: center;
    font-size: 35px;
    margin-top: 1.5em; 
    margin-bottom: 1em;
}

.contacts-container-parent {
    text-align: center;
    margin-top: 1em;
    height: 25em;
    padding-bottom: 3em;
}

.contacts-container {
    background-color: rgba(231, 240, 239, 0.5);
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 5px;
    box-shadow: 7px 4px 4px rgba(0, 0, 0, 0.192);
}

.contacts-container p {
    text-decoration: none;
    font-size: 1.5em;
    font-family: 'Tajawal', sans-serif;
}

.contacts-first-content {
    padding: 2em;
}

@media screen and (max-width: 900px) {
    .contacts-container-parent {
        height: 23em;
    }

    .contacts-container {
        width: 100%;
    }

    .contact-holder h2 {
        margin-top: 0em;
    }
}
.footer {
    height: 4em;
    display: flex;
    align-items: center; /*horizontal centering*/
    justify-content: center; /*vertical centering*/
    flex-direction: column; /*keep the h3 above the textbox*/
}

.line {
    width: 15%;
    height: 20px;
    border-bottom: 1px solid black;
    position: center;
    margin: 0em 0em 1.5em 0em;
    vertical-align: middle;
}

.copyright {
    position: center;
    vertical-align: middle;
    margin-bottom: 3em;
}
.title-text-services {
    text-align: center;
    font-size: 35px;
    margin-bottom: 1em;
    font-family: 'Lemonada', cursive;
}

.services-row {
    display: flex;
    align-items: left;
}

.services-item p {
    margin-left: 30px;
    margin-bottom: 20px;
    font-size: 17px;
    font-family: 'Lemonada', cursive;
    text-align: left;
}

.service-row {
    height: 2em;
}

.center-icon-pool {
    padding-top: 10px;
    display: flex;
    align-items: center;
}

.center-icon-bike {
    padding-top: 7px;
    display: flex;
    align-items: center;
}

.services-item-breakfast {
    padding-bottom: 22px;
    display: flex;
    align-items: center;
}

.services-item-forest {
    padding-bottom: 16px;
    display: flex;
    align-items: center;
}

.services-item-bed {
    padding-bottom: 32px;
    display: flex;
    align-items: center;
}

.services-list {
    margin-left: 9em;
    margin-right: 6em;
}

.service-container-parent {
    text-align: center;
    margin-top: 2em;
    height: 32em;
}

.service-container {
    background-color: rgba(231, 235, 234, 0.52);
    border-radius: 5px;
    width: 100%;
    height: 100%;
    display: inline-block;
    box-shadow: 7px 4px 4px rgba(0, 0, 0, 0.192);
}

.title-text-services {
    margin-top: 1em;
    margin-bottom: 1em;
}

@media screen and (max-width: 1400px) {
    .service-container-parent {
        height: 40em;
    }
}

@media screen and (max-width: 1200px) {
    .services-list {
        margin-left: 6em;
        margin-right: 3em;
    }
}

@media screen and (max-width: 800px) {
    .services-item p {
        font-size: 12px;
    }

    .services-list {
        margin-left: 1em;
        margin-right: 0.1em;
    }
}
