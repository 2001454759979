@import url('https://fonts.googleapis.com/css2?family=Lemonada:wght@300&family=Tajawal:wght@300&display=swap');

.title-welcome-content {
    font-family: 'Lemonada', cursive;
}

.container-welcome {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 300px;
    grid-gap: 5px;
    margin-left: 5em;
    margin-right: 5em;
    margin-bottom: 10px;
}

.container-item-welcome {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}


.title-welcome {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-content-welcome {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-content-welcome p {
    text-align: center;
    padding: 2em;
    font-size: 25px;
    font-family: 'Tajawal', sans-serif;
}

.title-welcome h2 {
    text-align: center;
    font-size: 33px;
}

.wrapper-line-welcome {
    display: flex;
    align-items: center;
    justify-content: center;
}

.line-welcome {
    width: 30%;
    height: 10px;
    border-bottom: 1px solid black;
    position: center;
    margin: 0em 0em 1.5em 0em;
    vertical-align: middle;
}

@media screen and (max-width: 900px) {
    .container-welcome {
        margin-top: 2em;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        margin-bottom: 50px;
        grid-auto-rows: 200px;
        margin-left: 2em;
        margin-right: 2em;
    }
    .title-welcome br {
        display: none;
    }

    .text-content-welcome p {
        font-size: 20px;
    }
}
