.title-contact {
    font-family: 'Lemonada', cursive;
    text-align: center;
    font-size: 35px;
    margin-top: 1.5em; 
    margin-bottom: 1em;
}

.contacts-container-parent {
    text-align: center;
    margin-top: 1em;
    height: 25em;
    padding-bottom: 3em;
}

.contacts-container {
    background-color: rgba(231, 240, 239, 0.5);
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 5px;
    box-shadow: 7px 4px 4px rgba(0, 0, 0, 0.192);
}

.contacts-container p {
    text-decoration: none;
    font-size: 1.5em;
    font-family: 'Tajawal', sans-serif;
}

.contacts-first-content {
    padding: 2em;
}

@media screen and (max-width: 900px) {
    .contacts-container-parent {
        height: 23em;
    }

    .contacts-container {
        width: 100%;
    }

    .contact-holder h2 {
        margin-top: 0em;
    }
}