.title-text-rooms {
    text-align: center;
    font-size: 35px;
    margin: 1em;
    font-family: 'Lemonada', cursive;
}

.rooms-top-container {
    padding-left: auto;
    padding-right: auto;
    text-align: center;
}