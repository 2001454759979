.container-img {
    margin-top: 5px;
    height: 80vh;
    background: url(./../images/outside/exterieur.jpeg) no-repeat center/cover fixed;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 800px) {
    .container-img {
        background: url(./../images/outside/exterieur_small.jpeg) no-repeat center/cover;
        background-size: cover;
    }
}
